<template>
  <div class="box box--white">
    <div v-if="formatStakingDataStatistics('users')" class="statistics">
      <statistic
        v-for="statistic in formatStakingDataStatistics('users')"
        :key="statistic.key"
        :title="statistic.title"
        :value="statistic.value | number_with_commas"
        :rating="statistic.rate"
        :value-class="statistic.valueClass"
      />
    </div>

    <div class="divider divider--expand-16 mt-24 mb-24"></div>

    <div class="d-flex align-start">
      <selection-box v-model="params.stakingPlan" :options="stakingPlanOptions" />

      <div class="d-flex">
        <date-range-picker
          v-model="params.dateContext"
          opens="left"
          format="yyyy-mm-dd hh:mm"
          @toggle="isDateRangeOpen = !isDateRangeOpen"
          :class="['mr-16 bg-gray', { active: isDateRangeOpen }]"
          :append-to-body="true"
          :date-format="dateFormat"
        />

        <selection-box
          v-model="params.groupBy"
          :options="getGroupByOptions()"
          :is-align-right="true"
          :enabled-values="getEnableGroupByValues()"
        />
      </div>
    </div>

    <line-chart class="mt-24" :chart-data="formatStakingDataChart('users')" />

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import dashboardStaking from '../mixins/dashboard-staking'
import dateFormat from '@/mixins/date-range-format'
import groupBy from '@/mixins/group-by'
import toggleLoading from '@/mixins/toggle-loading'

export default {
  mixins: [dashboardStaking, dateFormat, groupBy, toggleLoading],

  methods: {
    ...mapActions('report', ['getReportJoinedUsersChart'])
  },

  created() {
    this.getReportJoinedUsersChart(this.getChartRequestParams()).finally(() => {
      this.toggleLoading()
    })
  },

  watch: {
    params: {
      handler(newValue) {
        this.toggleLoading()
        setTimeout(() => {
          this.getReportJoinedUsersChart(this.getChartRequestParams(newValue)).finally(() => {
            this.toggleLoading()
          })
        }, 200)
      },

      deep: true
    }
  }
}
</script>
